<template>
  <div class="viewBox" ref="ul">
    <div class="header-footer"></div>
    <div class="rulebox">
      <div class="rulebtn" @click="service">
        <img src="../assets/img/kefu.png" />
      </div>
      <div class="custombtn" @click="showRuleFunc">
        <img src="../assets/img/rule.png" />
      </div>
      
    </div>
    <div class="backImg">
      <img
        src="../assets/img/duanju_bg.png"
        alt=""
        style="width: 100%; pointer-events: none"
      />
      <div class="moneyBox">
        {{ money + ".0" }}
      </div>
   
    </div>
    <div class="bodyS">
    
      <div style="padding: 0 4vw; margin-top: 12.2rem">
        <div class="rotation-box" v-if="barragesList && barragesList.length > 0">
          <van-swipe
            style="height: 1rem"
            vertical
            autoplay="2000"
            duration="1000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(item, index) in barragesList" :key="index">
              <div class="rotation-child">
                <div class="rotation_item">
                  <div
                    class="rotation_title"
                    style="
                      display: flex;
                      align-items: center;
                      font-size: 0.36rem;
                      font-weight: bold;
                    "
                  >
                
                    {{ item.title }}
                  </div>
                  <div class="rotation_text" style="color: #010101">
                    {{ item.text }}<span style="color: #ea3323">抽iphone15 pro福利</span>
                  </div>
                </div>
              </div>
            </van-swipe-item>
          </van-swipe>
       
        </div>
        <div class="btn_lq" style="margin-top: 0.25rem" @click="openModel(0)">
          立即支付
        </div>
        <div class="selectType">
          <div class="box">
            <div
              class="selectItem"
              v-if="payIcon == 1 || payIcon == 3 || payIcon == 4 || payIcon == 5"
            >
              <div class="box_flex">
                <img
                  src="../assets/img/wx.png"
                  alt=""
                  style="width: 0.47rem"
                />
                <span style="font-weight: 700; color: rgba(0, 0, 0, 0.9)">微信支付</span
                ><span style="color: red">(首单随机立减，最高至免单)</span>
              </div>
              <div>
                <img
                  src="../assets/img/select_in.png"
                  alt=""
                  style="width: 20px"
                />
              </div>
            </div>

            <div class="selectItem" v-if="payIcon == 2">
              <div class="box_flex">
                <img
                  src="../assets/img/ali.png"
                  alt=""
                  style="width: 0.47rem"
                />
                <span style="font-weight: 700; color: rgba(0, 0, 0, 0.9)">支付宝支付</span
                ><span style="color: red">(首单随机立减，最高至免单)</span>
              </div>
              <div>
                <img
                  src="../assets/img/select_in.png"
                  alt=""
                  style="width: 20px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="dowond" style="overflow:hidden">
          <div class="downLoad-app" @click="dowond_i">立即下载 <span style="margin-left:.12rem">万物盒ios</span>
          </div>
          <div class="downLoad-app" @click="dowond_a">立即下载 <span style="margin-left:.12rem">万物盒android</span>
          </div>
      </div> -->
    <!-- <div class="footerInfo" v-html='footerInfo'></div> -->

    <!-- <div class="footerInfo"></div> -->
    <div class="company">
      <div>客服电话：<span>4000868718</span></div>
      <div>无锡开吧科技有限公司</div>
      <div>备案号：苏ICP备2024087339号</div>
    </div>

    <div
      class="activity-mask"
      ref="rule"
      style="z-index: 1000"
      @click.stop="showRule = false"
      v-if="showRule"
    >
      <div class="activity-rule">
        <h3>规则</h3>
        <div v-html="ruleText" class="rule-content"></div>
        <div class="hideRule-image" @click.stop="showRule = false">我知道了</div>
      </div>
    </div>
   
    <div class="popup">
      <van-popup :show="show_popup">
        <div class="popup_box">
          <div style="position: relative">
            <img
              class="bg"
              src="../assets/img/bg_confirm.c2541d0e.png"
              alt=""
            />
            <div style="position: absolute; width: 100%" class="zhekou">
              仅需{{ money - 4 + ".0" }}元
            </div>
            <img
              src="../assets/img/ic_submit3.png"
              alt=""
              class="imgBtn"
              @click="openModel(23)"
            />
          </div>

          <img
            class="close"
            @click="close"
            src="../assets/img/close.png"
            alt=""
          />
        </div>
      </van-popup>
    </div>
    <div class="popup">
      <van-popup :show="show_popup1">
        <div class="popup_box">
          <div style="position: relative">
            <img
              class="bg"
              src="../assets/img/bg_confirm.c2541d0e.png"
              alt=""
            />
            <div style="position: absolute; width: 100%" class="zhekou">
              仅需{{ money - 10 + ".0" }}元
            </div>
            <img
              src="../assets/img/ic_submit3.png"
              alt=""
              class="imgBtn"
              @click="openModel(24)"
            />
          </div>

          <img
            class="close"
            @click="
              show_popup1 = false;
              show_popup2 = true;
            "
            src="../assets/img/close.png"
            alt=""
          />
        </div>
      </van-popup>
    </div>
    <!-- <div class="popup">
      <van-popup :show="show_popup2">
        <div class="popup_box">
          <div style="position: relative">
            <img
              class="bg"
              src="../assets/img/adv_tc.gif"
              alt=""
              @click="goAd"
            />
          </div>
          <img
            class="close"
            @click="show_popup2 = false"
            src="../assets/img/close.png"
            alt=""
          />
        </div>
      </van-popup>
    </div> -->
    <div class="popup">
      <van-popup :show="show_success">
        <!-- <div class="popup_box">
                  <img class="bg1" src="../assets/img/jiangping1.png" alt="" />
                  <img class="btn" @click="getPayment(19)" src="../assets/img/button_icon.png" alt="">
                  <img class="close" @click="show_popup1 = false" src="../assets/img/close.png" alt="">
              </div> -->
        <div class="popup_box">
          <div class="selectOrder" style="">
            <div class="itemText">确认支付情况</div>
            <div class="itemBtn">
              <div @click="getPayment(0)">重新支付</div>
              <div @click="paySucc">已支付完成</div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <van-popup
      v-model:show="surbtnShow"
      round
      position="bottom"
      closeable
      :style="{ height: '40%' }"
    >
      <div class="showBtnShow">
        <div class="postBt">
          <div class="title">限时优惠</div>
          
        </div>
        <div class="selectType">
          <div style="font-size: 0.3rem; font-weight: 600">支付方式</div>

          <div class="box">
            <div
              class="selectItem"
              v-if="payIcon == 1 || payIcon == 3 || payIcon == 4 || payIcon == 5"
            >
              <div class="box_flex">
                <img
                  src="../assets/img/wx.png"
                  alt=""
                  style="width: 0.56rem"
                />
                <span style="font-weight: 700; color: rgba(0, 0, 0, 0.9)">微信支付</span
                ><span style="color: red">(推荐随机立减最高至免单)</span>
              </div>
              <div>
                <img
                  src="../assets/img/select_in.png"
                  alt=""
                  style="width: 20px"
                />
              </div>
            </div>

            <div class="selectItem" v-if="payIcon == 2">
              <div class="box_flex">
                <img
                  src="../assets/img/ali.png"
                  alt=""
                  style="width: 0.56rem"
                />
                <span style="font-weight: 700; color: rgba(0, 0, 0, 0.9)">支付宝支付</span
                ><span style="color: red">(推荐随机立减最高至免单)</span>
              </div>
              <div>
                <img
                  src="../assets/img/select_in.png"
                  alt=""
                  style="width: 20px"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="shopTitle">
          <div>短剧VIP会员+抽888红包:</div>
          <div class="price">￥{{moneySure+'.0'}}</div>
        </div>
        <div class="btnSubmit" @click="getPayment(numBer)">立即支付</div>
       
      </div>
    </van-popup>
    <van-number-keyboard
      v-model="phone"
      :show="show_keyboard"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      @blur="show_keyboard = false"
      @input="Oninput"
    />
    <!-- <div v-show="false">
      <iframe v-if="iframe_rul" :src="iframe_rul" frameborder="0" id="iframe1"></iframe>
    </div> -->
  </div>
</template>

<script>
const throttle = (func, delay) => {
  // 缓存一个定时器
  let timer = null;
  // 这里返回的函数是每次用户实际调用的节流函数
  return function (...args) {
    if (!timer) {
      //判断timer是否有值,如果没有则说明定时器不存在即可继续执行
      timer = setTimeout(() => {
        //关
        func.apply(this, arguments);
        timer = null; //开
      }, delay);
    }
  };
};
import axios from "@/utils/http";
// import axios from 'axios'
import { closeDialog, closeToast, showDialog, showLoadingToast, showToast } from "vant";
export default {
  data() {
    return {
      money: 79,
      moneySure:79,
      numBer:0,
      surbtnShow:false,
      show_popup2: false,
      show_success: false,
      showRule: false, //规则弹窗
      ruleText:
        "<span>解锁短剧剧情费用说明：本链接下79元为“快趣看”微信公众号平台的20集剧情解锁费用，非商品直购费用，成功解锁短剧剧情即可参与抽手机活动（每人每日只能参与一次）。<br> 猜中剧情即有机会获得幸运奖〜每天每人限参与 1次，1次机会免费。此活动为概率中奖，奖品数量有限，祝好运！<br> 【中奖概率说明】<br>惊喜一：888元现金红包，中奖概率0.00001%<br>惊喜二：188元现金红包，中奖概率0.0001%<br>以上奖品，每人每天限领取1次<br>【特殊说明】<br> 本活动为用户自愿参与，活动主办方为无锡诚智教育信息科技有限公司，如有疑问请联系在线客服，客服电话：4000868718<br> 在活动中获得道具、奖品后，如退出活动，可能导致道具或奖品丢失，请在获得道具、奖品后及时兑换或领取，避免损失。<br> 【活动参与方式】<br> 1.点击消息中的公众号链接#公众号：快趣看，进入下一个页面<br> 2.点击关注按钮关注公众号：快趣看<br> 3.点击公众号中推送的相关链接进行短剧观看以及奖品抽取<br></span>",
      minutes: 5,
      phone: "",
      seconds: 0,
      milliseconds: 0,
      selectType: 0,
      countDownTimer: undefined,
      barragesList: [
        {
          src: "../assets/img/R-C.jpg",
          title: "张**",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/20213132143610585.jpg",
          title: "ai**hu",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/20211722215735941.jpg",
          title: "dd**hu",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/20205622141066864.jpg",
          title: "护**者",
          text: " 已领取看短剧",
        },
        {
          src: "../assets/img/R-C.jpg",
          title: "张**戴",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/15982538692827405.jpg",
          title: "ui**ty",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/20200824173610_74528.jpg",
          title: "oo**ty",
          text: " 已领取看短剧",
        },
        {
          src: "../assets/img/R-C.jpg",
          title: "tt**xx",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/20211722215735941.jpg",
          title: "de**ggh",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/15982538692827405.jpg",
          title: "好**",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/20213132143610585.jpg",
          title: "艾***莉",
          text: " 已领取看短剧",
        },
        {
          src:
            "../assets/img/20205622141066864.jpg",
          title: "离**",
          text: " 已领取看短剧",
        },
      ],
      footerBtnFlag: false,

      footerRule:
        "<span>*注意，手机号填错可能导致订单无法发货！</br> *运费在提货时按实际地区规则收取</br>*购买前请您仔细阅读<a >《用户协议》</a><a >《隐私协议》</a><a>《玩法规则》</a></span>",
      footerInfo:
        "<span><div style=margin-top:10px;text-align:center;font-size:12px>无锡开吧科技有限公司</div><div style=text-align:center;font-size:12px>备案号：沪ICP备2021013818号-1</div><div></div></span>",
      levelList: [
        {
          tag1: "image/small-tag1.png",
          price: "0.006%",
        },
        {
          tag1: "image/small-tag2.png",
          price: "63%",
        },
        {
          tag1: "image/small-tag3.png",
          price: "0.05%",
        },
        {
          tag1: "image/small-tag4.png",
          price: "16%",
        },
      ],
      search: "",
      showsuccess_pay: false,
      prize_list: [],
      level_list: [],
      box_id: 69,
      showimgBox: false,
      goods_box: {},
      user_id: "",
      order_id: "",
      goods_id: "",
      checked: "",
      payIcon: 2,
      show_popup: false,
      show_popup1: false,
      show_keyboard: false,
      openid: "",
      city: "",
      backNum: 0,
      //畅连平台单独参数
      buttonChange: false, //是否点击按钮
      returnUrl: "", //获取要跳转的页面
      iframe_rul: "",
      order_url_c: "",
    };
  },
  computed: {
    second: function () {
      return this.num(this.seconds);
    },
    minute: function () {
      return this.num(this.minutes);
    },
    millisecond: function () {
      return this.num(this.milliseconds);
    },
  },
  created() {
    const phone = this.getQueryString("phone");
    const coupon_id = this.getQueryString("coupon_id");
    const order_id = this.getQueryString("order_id");
    this.openid = this.getQueryString("openid");
    if (phone) {
      this.phone = decodeURIComponent(atob(phone));
      this.selectType = 1;
      this.checked = "1";
    }
    //   if(order_id){
    //       this.show_success=true
    //   }
    if (coupon_id) {
      this.show_popup = true;
    }
    // this.orderCountDown()

    this.getBoxList();
    // this.getPayment(0)
  },
  mounted() {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://ss.qlink.ink/js/monitor.v5.min.js";
    document.head.appendChild(s);
    window.addEventListener("scroll", this.scrolling);
    // window.addEventListener("pageshow", function (e) {
    //   console.log(493)
    //   if (
    //       this.buttonChange &&
    //       this.getQueryString("return_url") &&
    //       !document.hidden
    //     ) {
    //       this.buttonChange = false;
    //       window.location.href = unescape(this.getQueryString("return_url"));
    //     }
    // });
    //畅连支付监听页面进入后台并且是点击按钮后进入后台的
    document.addEventListener("visibilitychange", () => {
      // console.log(504)
      if (
        this.buttonChange &&
        (this.getQueryString("return_url") || this.order_url_c) &&
        !document.hidden
      ) {
        this.buttonChange = false;
        if (this.isBaiDu()) {
          // this.iframe_rul=''
          window.location.href = unescape(this.order_url_c);
        } else {
          window.location.href = unescape(this.getQueryString("return_url"));
        }
      }
    });
    const sc = document.createElement("script");
    sc.type = "text/javascript";
    sc.src = "https://pv.ip1398.com/";
    document.head.appendChild(sc);
    let that = this;
    window.onload = () => {
      that.city = returnCitySN["cname"];
      if (window.history && window.history.pushState) {
        // 向历史记录中插入了当前页
        console.log(111);
        window.onpopstate = that.goBack;

        window.history.pushState(null, null, document.URL);
      }
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
    window.removeEventListener("popstate", this.goBack, false);
  },
  methods: {
    openModel(num){
      this.surbtnShow=true
      this.numBer=num
      if(num==23){
        this.moneySure=this.money-4
      }
      if(num==24){
        this.moneySure=this.money-10
      }
      if(num==0){
        this.moneySure=this.money
      }
    },
    tuikuan() {
      window.location.href =
        "";
    },
    goBack() {
      // window.history.back();
      //阻止页面返回
      this.backNum = this.backNum + 1;
      console.log(this.city);
      var t1 = this.city.indexOf("浙江");
      if (t1 != -1 || this.backNum > 1) {
        window.history.back();
      } else {
        this.show_popup = true;
        window.history.pushState(null, null, document.URL);
      }
    },
    goAd() {
      window.location.href = "";
    },
    //点击查询支付是否完成
    paySucc() {
      let data = {
        order_id: this.getQueryString("order_id"),
        channel_id: this.getQueryString("channel_id"),
      };
      axios
        .post("/api/v1/video/payOrderSuccess", data)
        .then((res) => {
          if (res.data.code == 0) {
            this.show_success = false;
            window.location.href = "https://work.weixin.qq.com/kfid/kfc1fbe95a001b15c7d";
          }
        })
        .catch((err) => {
          showToast("未查询到已支付订单");
        });
    },
    phoneChange(e) {
      console.log(e);
      let val = e;
      const rep = /^1[3-9]\d{9}$/;
      if (val.length == 11) {
        if (!rep.test(val)) {
          showToast("请输入正确的手机号");
        } else {
          document.activeElement.blur();
          this.selectType = 1;
          this.checked = "1";
        }
      }
    },
    Oninput() {
      console.log(123);
    },
    service() {
      window.location.href = "https://work.weixin.qq.com/kfid/kfcb9d3a00fe6377dd9";
    },
    close() {
      this.show_popup = false;
      setTimeout(() => {
        this.show_popup1 = true;
      }, 300);
    },
    phoneOs() {
      var u = navigator.userAgent,
        isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
        isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      if (isAndroid) {
        return "Android";
      }

      if (isiOS) {
        return "IOS";
      }
      return "";
    },
    toDowond: throttle(function (bxm_id) {
      axios.post("/api/v1/tuia/subTam", {
        adv_m_id: bxm_id,
        order_id: this.order_id,
        adv_client_id: 2,
      });
    }, 800),
    changePhone(e) {
      console.log(e);
      let val = e.target.value;
      const rep = /^1[3-9]\d{9}$/;
      if (!rep.test(val)) {
        showToast("请输入正确的手机号");
        this.phone = "";
      } else {
        const bxm_id = this.getQueryString("bxm_id");
        if (bxm_id) {
          this.toDowond(bxm_id);
        }
      }
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return "";
    },
    getBoxList() {
      //   axios.post(`/api/v1/passport/wechatDoLogin?box_id=${this.box_id}`).then(res => {
      //       if(res.data.code == 0) {
      //         let text='<span>一、购买盲盒具有随机属性，请理性消费，活动截止时间：2023年12月31日。活动前10万名用户有机会获得奖励  玩法说明：</span>\n\n<span>1、用户在万物盒子中选择心仪的盲盒，点击开启盲盒，进行购买，会随机开出盲盒内的商品。购买可使用现金进行支付，当金币数量不足时可使用支付宝支付;  开启盲盒后，用户可选择回收或发货发货无运费。</span>\n<span>2、因为盲盒的特殊属性，盲盒一经开启，已发货的暂不支持退款，需要退款请联系客服进行处理，如因商品质量问题，可联系在线客服沟通退换货流程或前往APP查看详细的换货流程。所有中国大陆地区用户可在万物盒子APP内参与盲盒开盒活动，盲盒所开商品品均为正品。快递：申通、圆通、中通、韵达等，根据不同地区和商品品大小选择合适的快递发货</span>\n<span>3、开盒概率内容：每个盒子发放的商品有四个等级，不同等级的商品拥有不同的开盒概率，具体如下：传说款，史诗款，稀有款，普通款；以上概率仅供参考，其中传说款：iPhone14 Pro Max、九号EP200电动车、九号EP100电动车、康佳58寸液晶电视、三星GalaxyTab s8系列平板、DJI FPV套装、佳能200D2代、华为matepad英寸等， 史诗款：钛度巡洋舰电竞椅黑红经典款、syma司马X30无人机单电版、米家智能压力电饭煲、vivo y32、小米Redmi note11等， 稀有款：华为只选欧普智能台灯2S、康佳智能专业筋膜枪A1、联想XG02太空舱蓝牙耳机等 超值款：荣耀AM116半入耳机、映趣三刀头剃须刀、九阳多功能料理机、上亨豌豆保温杯等。盲盒商品会不定时调整。  </span>\n\n<span>奖品概率：</span>'
      //             this.level_list = res.data.data.level_list
      //             this.level_list.forEach(item => {
      //               item.icon = `${item.icon}?x-oss-process=image/resize,h_26,m_lfit`
      //               text=text+`\n\n<span>${item.name}：${item.probability}%</span>`
      //             })
      //             text=text+'\n\n<span>二、申请发货</span>\n\n<span>抽到商品后，在“仓库”内选择“申请发货”，填写您的收货地址，提交订单。我们会在1-7个工作日内将您的商品寄出。</span>\n\n<span>三、关于提货</span>\n\n<span>同一时间发往同一收货地址且收件人为同一人的商品超过3个（含3个），可享受平台提供的中国大陆地区的包邮服务，同一时间同一收件人发往同一收货地址的商品不足3个，用户需支付15元运费/次（虚拟商品除外，不需要支付运费）。偏远地区（指甘肃、宁夏、青海、内蒙古、新疆等地区）的用户不享受上述包邮，按每个产品15元/件收取运费，新疆每个产品按20元/件收取运费，西藏每个产品按25元/件收取运费，不享受满3件包邮；如有疑问请您主动联系客服处理。</span>\n\n<span>四、须知</span>\n\n<span>1、因“盲盒”类产品具有随机性的特点，一经开启非重大质量问题，不得以任何理由要求退货、换货，请知悉。对此您应该对您充值、消费行为的性质和后果自行加以判断，并对您在万物盒子内账户下的一切消费行为进行负责。</span>\n\n<span>2、万物盒子拒绝以未开到特定商品为由要求退款、赔偿或承担任何责任。不会对因前述问题而导致的任何损失和损害承担责任。若平台发现存在任何异常行为的用户，平台有权取消其参与资格，有权召回已发出商品。</span>\n\n<span>3、Apple未以任何方式参与竞赛或抽奖活动。</span>'
      //             this.ruleText=text
      //             this.prize_list = res.data.data.prize_list
      //             this.prize_list.forEach(item => {
      //               item.icon = `${item.icon}?x-oss-process=image/resize,h_51,m_lfit`
      //             })
      //       }

      //   })
      axios
        .post(`/api/v1/channel/getPayChannel`, {
          equipment_client: this.phoneOs() == "IOS" ? 3 : 2,
          pay_client: this.isWeixinBrowser() ? 5 : this.getQueryString("pay_client") || 3, //3: h5  2:xiaochengxu  1:app
          channel_no: this.getQueryString("channel_no"),
        })
        .then((res) => {
          this.payIcon = res.data.data.pay_way;
        });
    },
    aaa() {
      this.show_keyboard = true;
      window.scrollTo({
        top: 200,
        behavior: "instant",
      });
    },
    scrolling() {
      // 滚动条距文档顶部的距离
    },
    showRuleFunc() {
      this.showRule = true;
    },
    num: function (n) {
      return n < 10 ? "0" + n : "" + n;
    },
    orderCountDown() {
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer);
        this.countDownTimer = undefined;
      }
      var vm = this;
      this.countDownTimer = setInterval(function () {
        if (
          parseInt(vm.minutes) * 6000 +
            parseInt(vm.seconds) * 100 +
            parseInt(vm.milliseconds) -
            1 <=
          0
        ) {
          clearInterval(vm.countDownTimer);
          vm.milliseconds = 0;
          vm.seconds = 0;
          vm.minutes = 0;
          return;
        }
        var millisecond = vm.milliseconds;
        var second = vm.seconds;
        var minute = vm.minutes;
        vm.milliseconds =
          (parseInt(minute) * 6000 + parseInt(second) * 100 + parseInt(millisecond) - 1) %
          100;
        vm.seconds = Math.floor(
          ((parseInt(minute) * 6000 +
            parseInt(second) * 100 +
            parseInt(millisecond) -
            1) %
            6000) /
            100
        );
        vm.minutes = Math.floor(
          (parseInt(minute) * 6000 + parseInt(second) * 100 + parseInt(millisecond) - 1) /
            6000
        );
      }, 10);
    },
    encode(str) {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    updateQueryStringParameter(uri, key, value) {
      if (!value) {
        return uri;
      }
      uri = uri.split("#/").join("");
      var re = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
      var separator = uri.indexOf("?") !== -1 ? "&" : "?";
      if (uri.indexOf(key) !== -1) {
        return uri + "#/";
      }
      if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2") + "#/";
      } else {
        return uri + separator + key + "=" + value + "#/";
      }
    },
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    },
    getPayment(coupon_id) {
      let that = this;
      // if(!this.phone) {
      //     this.$refs.phoneInput.focus()
      //     return showToast('请输入手机号')
      // }

      // const rep = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      // if(!rep.test(this.phone)) {
      //     return showToast('请输入正确的手机号')
      // }
      // if(!this.checked) {
      //     return showToast('请勾选用户与隐私协议')
      // }
      const a_oId = that.getQueryString("a_oId");
      const bxm_id = that.getQueryString("bxm_id");
      const userkey = that.getQueryString("userkey");
      const qcjParamStr = that.getQueryString("qcjParamStr");
      const share_id = that.getQueryString("share_id");
      const landingid = that.getQueryString("landingid");
      const sz_rli = that.getQueryString("sz_rli");
      // const adv_client_appid = that.getQueryString('adv_client_appid')
      const adv_client_id = that.getQueryString("adv_client_id");
      let data = {};
      // let adv_client_id = 1
      if (a_oId) {
        data = { a_oId };
        // adv_client_id = 1
      }
      if (bxm_id) {
        data = { bxm_id };
        // adv_client_id = 2
      }
      if (userkey) {
        data = { userkey };
        // adv_client_id = 3
      }
      if (landingid) {
        data = { landingid };
        // adv_client_id = 3
      }
      if (sz_rli) {
        data = { sz_rli };
      }
      if (qcjParamStr) {
        data = { qcjParamStr };
      }
      let adv_m_id = this.get_object_first_attribute(data, 1);
      axios
        .post(`/api/v1/channel/getPayChannel`, {
          equipment_client: that.phoneOs() == "IOS" ? 3 : 2,
          pay_client: this.isWeixinBrowser() ? 5 : this.getQueryString("pay_client") || 3,
          channel_no: that.getQueryString("channel_no"),
        })
        .then(function (res) {
          console.log(res);
          if (res.data.code == 0) {
            let arrCruu = [{ key: "coupon_id", value: 1 }];
            let platfrom = res.data.data.platfrom;
            arrCruu.forEach((item) => {
              let newurl = that.updateQueryStringParameter(
                window.location.href,
                item.key,
                item.value
              );
              window.history.replaceState(
                {
                  path: newurl,
                },
                "",
                newurl
              );
            });
            if (!res.data.data.is_jump) {
              let datas = {
                pay_price: 59,
                phone: "18227687321",
                pay_type: res.data.data.pay_way,
                channel_no: that.getQueryString("channel_no"),
                channel_id: res.data.data.channel_id,
                coupon_id,
                goods_id: 1,
                callback_url:
                  window.location.origin +
                  `/?channel_no=${that.getQueryString("channel_no")}&openid=${
                    that.openid
                  }&coupon_id=1#/`,
                pay_mode: 1,
                goods_name: "手机",
                return_url: res.data.data.platform_url + "/#/vedioSuccess",
                adv_m_id,
                adv_client_id,
                openid: that.openid,
                pay_client: res.data.data.pay_client,
              };
              axios
                .post(`/api/v1/video/submit`, datas)
                .then((res) => {
                  that.surbtnShow=false
                  let order_url = escape(res.data.data.return_url);
                  that.order_url_c = escape(res.data.data.return_url);
                  if (res.data.code == 0) {
                    if (that.payIcon == 2) {
                      let arr = [
                        { key: "coupon_id", value: 1 },
                        { key: "payIcon", value: that.payIcon },
                        { key: "phone", value: that.encode(that.phone) },
                        { key: "order_id", value: res.data.data.order_id },
                        { key: "channel_id", value: res.data.data.channel_id },
                      ];
                      arr.forEach((item) => {
                        let newurl = that.updateQueryStringParameter(
                          window.location.href,
                          item.key,
                          item.value
                        );
                        window.history.replaceState(
                          {
                            path: newurl,
                          },
                          "",
                          newurl
                        );
                      });
                    }
                    //畅联平台
                    // if(platfrom=='11'){
                    //     //证明用户点击过后才进入后台
                    //     this.buttonChange = true;
                    //     this.returnUrl=res.data.data.return_url
                    // }
                    if (
                      platfrom == "6" ||
                      platfrom == "11" ||
                      platfrom == "13" ||
                      platfrom == "14" ||
                      platfrom == "17" ||
                      platfrom == "19"
                    ) {
                      //证明用户点击过后才进入后台
                      that.buttonChange = true;
                      // that.returnUrl=res.data.data.return_url
                      let newurl = that.updateQueryStringParameter(
                        window.location.href,
                        "return_url",
                        order_url
                      );
                      window.history.replaceState(
                        {
                          path: newurl,
                        },
                        "",
                        newurl
                      );
                    }
                    if (platfrom == "13") {
                      that.iframe_rul = "";
                      let url = res.data.data.pay_data.split("//");
                      let toUrl = res.data.data.pay_data;
                      that.iframe_rul = toUrl.slice(29);
                      // url.splice(4,0,'convertPayway')
                      let src = url[1].split("/");
                      src.splice(3, 0, "convertPayway");
                      let sure = src.join("/");
                      url = url[0] + "//" + sure;
                      axios
                        .post(url, {
                          wayCode: that.payIcon == 2 ? "ALI_WAP" : "WX_H5",
                          payDataType: "",
                        })
                        .then((bizData) => {
                          if (bizData.data.data.payDataType == "payurl") {
                            if (that.payIcon == 2) {
                              if (that.isBaiDu() && that.phoneOs() == "Android") {
                                let url1 = decodeURIComponent(
                                  bizData.data.data.payData.split(
                                    "alipays://platformapi/startapp?appId=20000067&url="
                                  )[1]
                                );

                                let url2 = url1.split("https:/")[1];
                                let url3 =
                                  "alipays://platformapi/startapp?appId=20000067&url=" +
                                  encodeURIComponent(url2);
                                let url4 =
                                  "https://ds.alipay.com/?scheme=" +
                                  encodeURIComponent(url3);
                                // let url4=location.origin+'/ali?scheme='+encodeURIComponent(url3)
                                // alert(url4)
                                // that.iframe_rul=url4
                                // alert(that.iframe_rul)
                                window.open(url4);
                                // location.href=url4
                                // alert(url4)

                                // showLoadingToast({
                                //   message: '支付拉起中...',
                                //     forbidClick: true,
                                //     duration: 0,
                                // })
                                //   let iframe=document.getElementById('iframe1')
                                //   let iframeWindow = iframe.contentWindow;
                                //   // alert(iframeWindow)

                                //   setTimeout(function(){
                                //     closeToast()
                                //     var button = iframeWindow.document.getElementsByClassName('paycashier-type')[3];
                                //           var event = new Event('click');
                                //           button.dispatchEvent(event);
                                //           var button2 = iframeWindow.document.getElementsByClassName('confirmBtn')[0];
                                //           var event2 = new Event('click');
                                //           button2.dispatchEvent(event2);
                                //     // alert(iframeWindow.document.getElementsByClassName('confirmBtn')[0])
                                //     // iframeWindow.document.getElementsByClassName('paycashier-type')[3].click()

                                //     // iframeWindow.document.getElementsByClassName('confirmBtn')[0].click()
                                //     // iframe.src=''
                                //     setTimeout(()=>{
                                //       iframe.src=''
                                //     },2000)
                                //   },2000)
                              } else if (that.isBaiDu() && that.phoneOs() == "IOS") {
                                window.location.href = bizData.data.data.payData;
                              } else {
                                window.location.href = bizData.data.data.payData;
                              }
                            } else {
                              var m = bizData.data.data.payData.match(
                                /wxaurl\.cn\/(\w+)/
                              );
                              if (m && m[1]) {
                                let mockUrlScheme = `weixin://dl/business/?t=${m[1]}`;
                                window.location.href = mockUrlScheme;
                              }
                            }
                          }
                        });
                    } else if (platfrom == "14") {
                      window.location.href =
                        "alipays://platformapi/startapp?saId=10000007&clientVersion=3.7.0.0718&qrcode=" +
                        encodeURIComponent(res.data.data.pay_data);
                    } else {
                      //window.open(res.data.data.pay_data)
                     
                      window.location.href = res.data.data.pay_data;
                    }
                  } else {
                    showToast(res.data.msg);
                  }
                })
                .catch((err) => {
                  console.log(err);
                  showToast(err);
                });
            } else {
              window.location.href = `
                      ${res.data.data.platform_url}?channel_id=${
                res.data.data.channel_id
              }&pay_price=59&phone=${that.phone}&pay_type=${
                res.data.data.pay_way
              }&goods_id=2&pay_mode=1&adv_m_id=${adv_m_id}&adv_client_id=${adv_client_id}&coupon_id=${coupon_id}&callback_url=${
                window.location.origin
              }&channel_no=${that.getQueryString("channel_no")}&payVideo=2&payIcon=${
                that.payIcon
              }&openid=${that.openid}&pay_client=${
                res.data.data.pay_client
              }&platfrom=${platfrom}&share_id=${share_id}`;
              that.phone = "";
            }
          } else {
            showToast(res.data.msg);
          }
        })
        .catch((err) => {});
    },
    isBaiDu() {
      var sUserAgent = navigator.userAgent.toLowerCase();
      if (sUserAgent.indexOf("baidu") > 0) {
        return true;
      }
      return false;
    },
    dowond_a() {
      window.open(
        "https://wanwu.bandiands.com/upload/app/20230310/6380288c871b91422f797475dae05bf0.apk"
      );
    },
    dowond_i() {
      window.open("https://apps.apple.com/cn/app/id1604779545");
    },
    get_object_first_attribute(data, val) {
      for (var key in data) {
        if (val) {
          return data[key];
        } else {
          return key;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes scaleAnimate {
  100% {
    transform: scale(1.05);
  }
}
@keyframes couponScaleAnimate {
  100% {
    transform: scale(1.1);
  }
}
@keyframes subumitScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.86);
  }
  100% {
    transform: scale(1);
  }
}
.selectOrder {
  width: 7.74rem;
  background-color: #fff;
  border-radius: 0.5rem;
  font-size: 16px;
  .itemText {
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 24px;
  }
  .itemBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      width: 50%;
      text-align: center;
      border-top: 1px solid #e9e9e9;
      padding: 0.3rem 0;
      &:first-child {
        border-right: 1px solid #e9e9e9;
      }
      &:nth-child(2) {
        color: #cd0600;
      }
    }
  }
}
.viewBox {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  background-color: #e83b1b;
  position: relative;
  .backImg {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .bodyS {
    position: relative;
    z-index: 1;
    padding: 0 4vw;
  }
  .money_su {
    position: absolute;
    z-index: 2;
    top: 5.08rem;
    right: 1.77rem;
    font-size: 0.5rem;
    font-style: italic;
    font-family: AlibabaPuHuiTi_2_115_Black;
    font-weight: 400;
    font-style: italic;
    color: #e83b1b;
    line-height: 0.587rem;
    -webkit-text-stroke: 2px #fff;
    background: linear-gradient(186deg, #fef9ee 0%, #fff6ad 87.6708984375%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .btn_lq {
    width: 100%;
    background: #e83b1b;
    color: #fff;
    font-weight: bold;
    border-radius: 0.8rem;
    font-size: 0.75rem;
    text-align: center;
    height: 1.6rem;
    line-height: 1.6rem;
    animation: subumitScale 1s linear alternate infinite;
  }
  .selectType {
    margin-top: 0.1rem;
    .box {
    }
    .selectItem {
      font-family: PingFang SC;
      font-size: 14px;
      height: 46px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      img {
      }
    }
    .selectItemBorder {
      border-bottom: #e1e1e1 1px solid;
    }
  }

  // .footerInfo{
  //   position: relative;
  //   top: 40px;
  // }

  .company{
    margin-top: 100px;
    color: #fff;
  }
}
.rulebox {
  top: 1rem;
}

.imgBox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.getPaymentClass {
  height: 1.24rem;
  line-height: 1.24rem;
  background: #e74f3f;
  border-radius: 0.72rem;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 0.67rem;
  letter-spacing: 2px;
  margin-bottom: 0.48rem;
  animation: scaleAnimate 0.8s linear alternate infinite;
}
.input-outer {
  width: 100%;
  height: 11.733vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 12px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin: 0.32rem 0;
  font-size: 14px;
  .title {
    width: 3.8rem;

    font-weight: 600;
    font-size: 3.733vw;
    color: #141415;
    padding-left: 3.2vw;
  }
  /deep/ .van-field__control {
    font-size: 4.533vw;
  }
}
.radio {
  margin: 3.2vw auto 0;
  margin-bottom: 3.2vw;
  font-size: 0.34rem;
}
.popup {
  /deep/.van-popup {
    background: rgba(0, 0, 0, 0) !important;
    width: 100%;
    padding: 1.07rem 0;
  }
  .popup_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .bg {
      width: 8rem;
    }
    .zhekou {
      color: #f42e01;
      font-size: 1.1rem;
      font-weight: 700;
      font-style: italic;
      position: absolute;
      top: 3.5rem;
      text-align: center;
      width: 323px;
    }
    .imgBtn {
      position: absolute;
      top: 5.4rem;
      left: 50%;
      width: 80%;
      left: 10%;
    }
    .bg1 {
      width: 7.29rem;
    }
    .btn {
      width: 7.86rem;
      margin-top: 0.43rem;
      animation: couponScaleAnimate 0.4s linear alternate infinite;
    }
    .close {
      position: absolute;
      bottom: -1rem;
      right: 45%;
    }
  }
}
/deep/.van-cell {
  padding: 0;
}
.order_button {
  padding: 0 4vw;
  position: relative;
  img {
    width: 100%;
  }
  .order_button_text {
    position: absolute;
    top: 50%;
    left: 8%;
    transform: translate(0%, -50%);
    color: #fff;
    span {
      font-size: 4.533vw;
      font-weight: 700;
    }
    .limit-time-label-money {
      font-size: 8.533vw;
      font-family: "SF Pro Text";
      font-weight: 700;
      line-height: 1;
      color: #fff;
    }
  }
  .order_button_click {
    background: #ffa29a;
    color: #fff;
    position: absolute;
    top: 28%;
    right: 6.5%;
    transform: translate(0%, -50%);
    padding: 4px 14px;
    border-radius: 20px;
    animation: subumitScale 0.8s infinite;
    font-size: 0.425rem;
  }
}
.pad-auto {
  padding: 0 3.25vw;
}
.celv {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 10px;
  color: #666;
}
.select_box {
  margin-left: 5px;
  margin-right: 10px;
  width: 13px;
  height: 13px;
  img {
    width: 100%;
    height: 100%;
  }
}
.pos_size {
  position: absolute;
  font-size: 0.69rem;
  bottom: 0.12rem;
  right: 0.3rem;
  font-family: "PingFang SC";
  font-weight: 600;
  color: #cd0600;
}
.moneyBox {
  position: absolute;
  top: 5.05rem;
  right: 1.62rem;
  font-size: 0.55rem;
  font-weight: bold;
  color: #fff6a8;
}
.postBt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e6e6e6;
  .title {
    font-size: 0.4rem;
    font-weight: 600;
    color: #111;
  }
  .time {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
    span {
      background: #ff0d36;
      color: #fff;
      font-size: 0.3rem;
      display: block;
      padding: 0.08rem;
      border-radius: 0.08rem;
    }
  }
  
}
.showBtnShow{
  .selectType {
  width: 95%;
  margin: 0.2rem auto;
  border-bottom: 1px solid #e6e6e6;
  .selectItem {
    font-family: PingFang SC;
    font-size: .4rem;
    height: 46px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .selectItemBorder {
    border-bottom: #e1e1e1 1px solid;
  }
}
}
.shopTitle {
  display: flex;
  width: 95%;
  margin: 0 auto;
  padding: 0.3rem 0;
  justify-content: space-between;
  align-items: center;
  font-size: 0.4rem;
  font-weight: 600;
  border-bottom: 1px solid #e6e6e6;
  .price {
    color: red;
    font-size: 0.5rem;
  }
}
.btnSubmit {
  width: 95%;
  margin: auto;
  background-color: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.4rem;
  border-radius: 8rem;
  font-weight: 700;
  margin-top: 0.4rem;
  padding: 0.5rem 0;
  text-align: center;
}
.moneyBox{
  position: absolute;
  top: 5.05rem;
  right: 1.62rem;
  font-size: .55rem;
  font-weight: bold;
  color: #fff6a8;
}
</style>
